import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import MainFooter from '../../components/homelayout/footer';
import { Helmet } from 'react-helmet-async';

const practiceAreas = {
  "LAND LAW AND PROPERTY CONVEYANCING": {
    title: "LAND LAW AND PROPERTY CONVEYANCING",
    content: [`The Firm is privileged to have lawyers possess a wealth of knowledge and experience on Land/Property and Conveyancing Law. We advise on property acquisition and development and further prepare the requisite Instruments to necessitate the acquisition Including survey and planning laws.`,

`We also prepare the necessary documentation for transfer of immovable properties and register Mortgages and Charges in favor of our clients. The Firm also provides advice on real estate matters and litigate in respect of all property-related matters.
`]
  },
  "MINING, ENERGY, OIL AND GAS": {
    title: "MINING, ENERGY, OIL AND GAS",
    content: [`Tanzania is one of the African Countries endowed with rich mineral deposits ranginfom Gold, Diamonds, Platinum and various types of gemstones among others. It is also home of a huge discoveries of energy sources such as oil, gas, coal, Iron etc.`,

`Presence of these resources has attracted a number of both international and local Investors. Large energy and mining investments require expertise, experience, skills, techniques, innovation to meet various demands and interests. The Firm recognizes all these needs.`,

`With its vast experience in both local and international know how coupled with good relationships with stakeholders and a broad ranging of skills and experiences in land and conveyancing, corporate, competition, mergers & acquisitions, tax, securities, environmental law etc., the Firm is dedicated to deliver composite legal services to assist its clients realizing their investment plans and goals.
`]
  },
  "CORPORATE LAW": {
    title: "CORPORATE LAW",
    content: [`There are various ways to do business in Tanzania and worldwide. While our clients focus on their operations, we handle their legal needs related to commercial matters. These include company incorporation, registration of trusts, NGOs, charities, partnerships, foreign branches, representative offices, and registration with the Tanzania Investment Centre (TIC), as well as de-registration, liquidation, and winding-up of companies.`,

`Our Corporate Law Team is known for its expertise and commitment to quality. We help clients navigate legal challenges in their daily business operations. We also offer services in business law, commercial law, company law, partnerships, labour and employment law, and international trade law.
We advise on transactions like the sale of shares, businesses, and assets, as well as mergers, acquisitions, takeovers, and drafting complex contracts such as joint ventures, distribution, agency, and franchise agreements. Our team conducts due diligence and provides reports on corporate matters, along with advising on tax implications for various commercial transactions.`,

`Through years of experience working with national and multinational clients, including serving as legal advisors for listings on the Dar es Salaam and London Stock Exchanges, our team has gained extensive knowledge. We also conduct searches at registries like BRELA and Land Registries.
`]
  },
  "SECRETARIAL SERVICES SUPPORT": {
    title: "SECRETARIAL SERVICES SUPPORT          ",
    content: [`The Firm has a dedicated Company Secretarial Department comprised of experienced and committed lawyers. This department provides expert and practical assistance to local and multinational companies, their Boards of Directors and Managements and all companies' compliance issues. In particular we act as company secretaries of various local and International companies, maintenance of statutory records and record-books.`,

`The Firm also provides advisories on all matters concerning shares, meetings of directors and shareholders and all other compliance issues.
`]
  },
  "INTELLECTUAL PROPERTY LAW": {
    title: "INTELLECTUAL PROPERTY LAW",
    content: [`The Firm offers broad -based services in this area including advice on acquisition, registration, protection, exploitation and litigation of Intellectual Property Rights. These rights include: trade and service marks, patents, Industrial designs and copyright. The Firm has put in place effective and proactive trademark clearance and registration strategies to ensure that all registered brands are properly protected`,

    `In particular the Firm provides advisories on trademarks clearance searches and registration, maintenance and renewal, auditing trademarks portfolio, trademarks docketing, and trademarks acquisition and assignment, trademarks conflict prevention and resolution etc. The Firm is also very goed in handling trademarks litigations at the office of the Registrar of trademarks, Fair Competition Commission (FCC), Fair Competition Tribunal (FCT) and the Court of Appeal of Tanzania.
    `]
  },
  "BANKING AND FINANCIAL SERVICES": {
    title: "BANKING AND FINANCIAL SERVICES",
    content: [
      `The Firm has made several legal advisories in various banks and financial institutions. The firm's attorneys have specialized knowledge and experience in banking and finance law to enable them to efficiently assist financial institutions in their day-to-day operations. We advise our clients on regulatory matters and prepare the essential documentation.`,
      `We, in particular, offer expert services on:`,
      [
        "Project financing",
        "Restructuring and recapitalization",
        "Banking transaction documentation including bank guarantees, letters of credit, and other trade finance documentation",
        "Preparation, drafting, and execution of lending and security documents",
        "Advice on all aspects of banking",
        "Advice on international development projects",
        "Banking compliance rules and regulations",
        "Preparation of contracts and various financing documentation",
        "Registration of securities at respective registries",
        "Recovery and litigation proceedings at every level"
      ]
    ]
  },
  "LABOUR AND EMPLOYMENT LAW": {
    title: "LABOUR AND EMPLOYMENT LAW",
    content: [
      `The Firm advises Its clients on all legal aspects of employment and labour law to ensure full compliance with all the applicable laws, regulations and rules. We offer a comprehensive range of professional services on labour and employment matters as follows
`,
      [
        "Drafting of Employment Contracts",
        "Advice on the labour implications on acquisitions and mergers",
        "Advice on termination of employment, retrenchment and rationalization",
        "Advice on alternative means of dispute resolution, including mediations and arbitration",
        "Formulation of strategies in preventing strikes, lock-outs and other forms of industrial action",
        "Representation in the labour courts and institutions",
        "Advice on unfair labour practices",
        "Advice on statutorily-based employment conditions and minimum standards",
        "Industrial law litigation",
        "Processing of Work Permits and Residence Permits for employees of foreign investors etc."
      ]
    ]
  },
  "REGULATORY LAW": {
    title: "REGULATORY LAW",
    content: [
      `Privatization in Tanzania has led to the enactment of regulatory laws and creation of regulatory bodies In areas such as telecommunications, broadcasting, water and energy, transportation, banking and many other areas. Compliance with the regulatory requirements Is becoming more complex and costly. Investigations by various authorities and enforcement are Increasingly frequent and more aggressive.
`,
`The Firm has over the years exhibited competency on knowledge of procedural rules in dealing and achieving results In Competition Investigations and Inquiries; mergers and acquisitions; regulatory proceedings before the Fair Competition Commission, the Fair Competition Tribunal, ENURA, SUMATRA.
`,
`TCRA: and high profile private antitrust lawsuits before the High Court (Commercial Division and the Main Division) and the Court of Appeal of Tanzania. This department also houses regulatory lawyers who have offered services to a wide range of local and international clients in the area of Tobacco (both downstream and upstream), Telecommunication, Energy, Water, Transport, and Maritime among others. We understand that compliance to the regulatory regime is important to the sustainability of a business. We usually work closely with the regulatory authorities in Identifying the specific sector risks and advise our clients on how to manage such risks. In this regard, we boast a strong relationship with regulatory authorities in Tanzania.
`,
  
    ]
  },
  "AVIATION, CARRIAGE BY SEA AND MARITIME LAW": {
    title: "AVIATION, CARRIAGE BY SEA AND MARITIME LAW",
    content: [
      `The development of aviation law in Tanzania has paralleled the growth and development of the aviation industry. Tanzanian transportation law both on land and sea are largely based on English Law on the same subjects.
`,
`Given that Tanzania Is a prominent multi sea-ports country the Firm specializes in dealing with all sorts of shipping and admiralty related contentious and non-contentious matters. Lawyers from the Firm have represented various foreign clients on matters relating to arrest of vessels as well as suits involving cargo claims.
`,
`The firm stands ready to offer advice pertaining to aviation law and matters relating to the industry as well.Areas of specialization include
`,
      [
        "Interpretation of insurance policies",
        "Advice regarding insurance claims",
        "Analysis of international conventions",
        "Advice on licensing air transportation",
        "Accident inquiries and advice on liability claims (cargo and passengers)",
        "Advice on sea transportation",
        "Maritime Law and Shipping generally",
        "Ocean Management and Coastal Zone Law",
      ]
    ]
  },
  "COMPETITION, MERGERS & ACQUISITION": {
    title: "COMPETITION, MERGERS & ACQUISITION",
    content: [
      `The Firm has built immense capacity in this area. It has had advantage of advising and representing various corporate clients from within and outside Tanzania on Competition and Mergers & Acquisition.
`,
    ]
  },
  "TAX LAW": {
    title: "TAX LAW",
    content: [
      `We understand the Legal intricacies of Tanzania Tax regime. Our Partners, Associates and Consultants have established a solid track record for high quality and sound as well as extensive experience in negotiating and contesting tax disputes including matters concerning double-taxation treaties with various countries.
`,
`Specialist services offered by the Firm on this category include
`,
      [
        "Advice on al tax matters, including the most tax-effective structuring of transactions and the selection of appropriate tax structures for the establishment of new businesses",
        "Transfer pricing and transfer pricing policies",
        "Negotiating with Tanzania Revenue Authority and the Ministry of Finance",
        "Litigating on tax matters in the appropriate courts, including Tax Revenue Appeals Board. Tax Revenue Appeals Tribunal and at the Court of Appeal",
      ]
    ]
  },
  "INSURANCE LAW": {
    title: "INSURANCE LAW",
    content: [
      `The Firm offers legal services in the Insurance industry, In particular, we advise on the insurance legislation and litigation on policy liability or repudiated claims. We advise on the regulatory aspects jand compliance with the applicable legislation. Our Attorney's assist in assessment of liability on policy and analyze product liability claims with a view to resolving such claims. A complete range of services Include advice on
`,
      [
        "Aviation and marine insurance",
        "Professional indemnity",
        "Financial Institutions - Banker Blanket Bond",
        "Product liability",
        "Fire, accident and general liability insurance",
        "Reinsurance",
        "Policy drafting and underwriting",
        "Disputes resolution and litigation; etc"
      ]
    ]
  },
  "ENVIRONMENTAL LAW": {
    title: "ENVIRONMENTAL LAW",
    content: [
      `We have responded to the increasing demand for services in this area. Staffed by consultants, we can assist clients to accommodate the need for environmental protection. To avoid inevitable conflict arising from non-compliance of environment laws we offer the following services;
`,
      [
        "Advice on legislation relating to the environment",
        "Advice on risk assessment",
        "Coordination of environmental impact assessment studies",
        "Civil litigation",
        "Defending criminal prosecutions",
        "Dispute resolution through mediation",
        "Making representations to public authorities",
        "Updating clients on developments in environmental law",
        "Advise on Environment Management Assessment (EMA); etc"
      ]
    ]
  },
  "GENERAL LITIGATION AND ALTERNATIVE DISPUTE RESOLUTION": {
    title: "GENERAL LITIGATION AND ALTERNATIVE DISPUTE RESOLUTION",
    content: [
      `Litigation Is an unpleasant experience. That is why we try as much as we can in litigation prevention. However, If push comes to shove, we confidently and aggressively represent our clients in all the courts In Tanzania Including in ombudsman and tribunals.
`,
`Before, we resort to litigation our lawyers constantly evaluate each case on Its own merit During evaluation, we look at realistic outcome prior and throughout the litigation process so as to ensure the best outcome for our clients.
`,
`The Firm has a good ethical and rectitude track record in both civil litigation and criminal litigation.
`,
`The Firm provides a specialized and quality legal advice on issues of litigation arising from various areas of the law. Our practice areas include;
`,
      [
        "Banking and financial institutions related Disputes",
        "Telecommunications",
        "Construction disputes",
        "Arbitration",
        "Commercial and industrial disputes",
        "Commercial frauds (Including urgent attachment orders)",
        "Mining disputes",
        "Personal injury claims",
        "Land disputes",
         "Criminal law"
      ]
    ]
  },
  // Add more practice areas here...
};

const Services = () => {
  const [selectedArea, setSelectedArea] = useState("LAND LAW AND PROPERTY CONVEYANCING");
  const titleRef = useRef(null); 

  useEffect(() => {
    // Initialize Owl Carousel for the services gallery
    if (window.$ && window.$.fn.owlCarousel) {
      window.$('.services-carousel').owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 6000,
        smartSpeed: 500,
        navText: [
          '<span class="fa fa-angle-left"></span>',
          '<span class="fa fa-angle-right"></span>',
        ],
        responsive: {
          0: { items: 1 },
          600: { items: 2 },
          800: { items: 2 },
          1024: { items: 3 },
          1200: { items: 3 },
        },
      });
    } else {
      console.error('jQuery or Owl Carousel is not loaded.');
    }
  }, []);

  const handleAreaClick = (area) => {
    setSelectedArea(area);

    // Scroll to the title section smoothly
    setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 200);
  };


  return (
    <div className="page-wrapper">
        <Helmet>
                          <title>Practice Areas | Law Associates Advocates</title>
                        </Helmet>
      <Header/>
      <HiddenBar/>
      <section className="page-title" style={{ backgroundImage: "url(https://html.themexriver.com/lasight/images/background/4.jpg)" }}>
        <div className="container">
          <div ref={titleRef} className="content">
            <h1>Practice Areas</h1>
            <ul className="page-breadcrumb">
              <li><a href="index.html">Home</a></li>
              <li>Practice Areas</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="sidebar-page-container">
        <div className="container">
          <div className="row clearfix">
            {/* Sidebar */}
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar padding-right">
                <div className="sidebar-widget sidebar-blog-category">
                  <ul className="blog-cat">
                    {Object.keys(practiceAreas).map((area) => (
                      <li 
                        key={area} 
                        className={selectedArea === area ? "active" : ""}
                        onClick={() => handleAreaClick(area)}
                        style={{ cursor: 'pointer' }}
                      >
                        <a>{area}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>

            {/* Content */}
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              <div className="services-single">
                <h4>{practiceAreas[selectedArea].title}</h4>
                <div className="text">
                {practiceAreas[selectedArea].content.map((item, index) => {
                    if (Array.isArray(item)) {
                      return (
                          <ul key={index} className="list-style-five">
                             {item.map((listItem, i) => (
                            <li key={i}>{listItem}</li>
                          ))}
                          </ul> 
                      );
                    }
                    return <p key={index}>{item}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainFooter/>
    </div>
  );
};

export default Services;


          