import React, { useEffect, useState } from 'react';
import MainFooter from '../../components/homelayout/footer';
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import { useParams } from "react-router";
import client from '../../configs/contentfulClient';
import ReactMarkdown from 'react-markdown';

const BlogSingle = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(id);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await client.getEntry(id);
        if (!response) throw new Error("No data found");

        console.log(response);
        
        const { blogtitle, content, coverphoto } = response.fields;

        setBlogPost({
          title: blogtitle,
          content: content.content.map(block => block.content.map(textNode => textNode.value).join("\n")).join("\n\n"),
          coverPhoto: coverphoto.fields.file.url
        });
      } catch (err) {
        setError("Failed to load blog post.");
        console.error("Error fetching blog post:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  return (
    <div className="page-wrapper">
      <Header/>
      <HiddenBar/>
      <section className="page-title" style={{ backgroundImage: "url(https://html.themexriver.com/lasight/images/background/4.jpg)" }}>
        <div className="container">
          <div className="content">
            <h1>{blogPost?.title}</h1>
            <ul className="page-breadcrumb">
              <li><a href="/">Home</a></li>
              <li>Blog Detail</li>
            </ul>
          </div>
        </div>
      </section>
      
      <div className="sidebar-page-container">
        <div className="container">
          <div className="row clearfix">
            <div className="content-side col-lg-8 offset-lg-2 col-md-12 col-sm-12">
              <div className="blog-single">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : blogPost ? (
                  <div className="inner-box">
                    <div className="image">
                      <img src={blogPost.coverPhoto} alt={blogPost.title} />
                    </div>
                    <div className="lower-content">
                      <h4>{blogPost.title}</h4>
                      <div className="text">
                        <ReactMarkdown>{blogPost.content}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter/>
    </div>
  );
};

export default BlogSingle;
