import React, { useEffect, useState } from 'react';
import { Link } from 'react-router'; // Corrected import
import client from '../../configs/contentfulClient';
import MainFooter from '../../components/homelayout/footer';
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import { Helmet } from 'react-helmet-async';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await client.getEntries({ content_type: 'blogPost' });
        const sortedPosts = response.items.sort((a, b) => 
          new Date(b.sys.createdAt) - new Date(a.sys.createdAt)
        );
        setBlogPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Publications | Law Associates Advocates</title>
      </Helmet>

      <Header />
      <HiddenBar />

      <section className="page-title" style={{ backgroundImage: 'url(https://html.themexriver.com/lasight/images/background/4.jpg)' }}>
        <div className="container">
          <div className="content">
            <h1>Publications</h1>
            <ul className="page-breadcrumb">
              <li><Link to="/">Home</Link></li>
              <li>Publications</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-page-section">
        <div className="container">
          <div className="row clearfix">
            {loading ? (
              <p>Loading blog posts...</p>
            ) : (
              blogPosts.map((post) => {
                console.log(post)
                const { id, createdAt } = post.sys;
                const { blogtitle, thumbnail } = post.fields;
                return (
                  <div key={id} className="news-block col-lg-4 col-md-6 col-sm-12">
                    <Link to={`/blogdetails/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="image">
                          <img src={thumbnail.fields.file.url} alt={blogtitle} />
                          <div className="overlay-box">
                            <a href={thumbnail.fields.file.url} data-fancybox="news" className="plus flaticon-plus"></a>
                          </div>
                        </div>
                        <div className="lower-content">
                          <ul className="post-meta">
                            <li><span className="fa fa-calendar"></span>{new Date(createdAt).toLocaleDateString()}</li>
                            <li><span className="fa fa-user"></span>author</li>
                          </ul>
                          <h5>{blogtitle}</h5>
                          <Link to={`/blogdetails/${id}`} className="theme-btn btn-style-three">View more</Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>

      <MainFooter />
    </div>
  );
};

export default Blog;
