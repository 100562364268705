import React, { useEffect, useState } from 'react';
import { Link } from 'react-router'; // Import Link from react-router-dom
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import MainFooter from '../../components/homelayout/footer';
import TeamSection from '../home/team';
import client from '../../configs/contentfulClient';
import { Helmet } from 'react-helmet-async';
const Management = () => {

  const [teamMembers, setTeamMembers] = useState([]);
  // Use useEffect to handle jQuery-based functionality
  useEffect(() => {
    if (window.$) {
      // Add any jQuery-based initialization here if needed
    } else {
      console.error('jQuery is not loaded.');
    }
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await client.getEntries({ content_type: 'team' });

        // Reverse the order of items
        const sortedMembers = response.items.sort((a, b) => 
          new Date(a.sys.createdAt) - new Date(b.sys.createdAt)
        );

        setTeamMembers(sortedMembers);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div className="page-wrapper">
       <Helmet>
              <title>About Us | Law Associates Advocates</title>
            </Helmet>
      {/* Preloader */}
      {/* <div className="preloader"></div> */}

      {/* Main Header */}
      <Header />

      {/* Form Back Drop */}
      <div className="form-back-drop"></div>

      {/* Hidden Bar */}
      <HiddenBar />

      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: "url('https://html.themexriver.com/lasight/images/background/4.jpg')" }}>
        <div className="container">
          <div className="content">
            <h1>About Us</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>About us</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <TeamSection />

      {/* Our Team Title and Description */}
      <div style={{ marginTop: 100 }} className="section-title centered">
        <div className="titletwo">Our Team</div>
        <h3> We are committed to protecting your rights with <br />expertise of our experienced{' '}
          <span className="spantwo">professionals</span>
        </h3>
      </div>

      {/* Team Members Grid */}
      <div className="container">
        <div className="row">
          {/* Team Member 1 */}
          {teamMembers.length > 0 ? (
                      teamMembers.map((member) => {
                        const { id } = member.sys;
                        const { membername, education, profilephoto, linkedinlink } = member.fields;
          
                        return (
                          <div className="team-block col-lg-3 col-md-6 col-sm-12">
                          <Link to={`/teamdetails/${id}`}> {/* Wrap the card with Link */}
                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                              <div className="image">
                                <img src={profilephoto.fields.file.url} alt={membername} />
                              </div>
                              <div className="lower-content">
                                <h3>{membername}</h3>
                                <div className="designation">{education}</div>
                                <div className="overlay-box">
                                  <div className="overlay-content">
                                    <div className="title">Contact info</div>
                                    <ul className="social-icons">
                                      <li>
                                        <a href={linkedinlink}>
                                          <span className="fa fa-linkedin"></span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                          // <div key={id} className="team-block col-lg-3 col-md-6 col-sm-12">
                          //   <Link to={`/teamdetails/${id}`}>
                          //     <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                          //       <div className="image">
                          //         <img src={profilephoto.fields.file.url} alt={membername} />
                          //       </div>
                          //       <div className="lower-content">
                          //         <h3>{membername}</h3>
                          //         <div className="education">{education}</div>
                          //         <div className="overlay-box">
                          //           <div className="overlay-content">
                          //             <div className="title">Contact info</div>
                          //             <ul className="social-icons">
                          //               <li>
                          //                 <a href={linkedinlink} target="_blank" rel="noopener noreferrer">
                          //                   <span className="fa fa-linkedin"></span>
                          //                 </a>
                          //               </li>
                          //             </ul>
                          //           </div>
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </Link>
                          // </div>
                        );
                      })
                    ) : (
                      [...Array(12)].map((_, index) => (
                        <div key={index} className="team-block col-lg-3 col-md-6 col-sm-12">
                          <div className="inner-box skeleton">
                            <div className="image skeleton-box"></div>
                            <div className="lower-content">
                              <h3 className="skeleton-box" style={{ width: '80%' }}></h3>
                              <div className="designation skeleton-box" style={{ width: '60%' }}></div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}

        </div>
      </div>

      {/* Footer */}
      <MainFooter />
    </div>
  );
};

export default Management;