import React from 'react';

const HiddenBar = () => {
  return (
    <section className="hidden-bar right-align">
      <div className="hidden-bar-closer">
        <button>
          <span className="fa fa-remove"></span>
        </button>
      </div>
      {/* Hidden Bar Wrapper */}
      <div className="hidden-bar-wrapper">
        <div className="inner-box">
          <div className="logo" style={{marginTop: -80}}>
            <a href="/">
              <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737803805/LAA_Logo-01_1_wwkwi1.png" alt="" />
            </a>
          </div>
          <div className="text">We are a Well Established and Experienced Law Firm, Offering a Full Range of Legal Services with a High Level of Professionalism since 1991.</div>
          {/* List Style Four */}
          <ul className="list-style-four">
            <li>
              <span className="icon flaticon-house"></span>CRDB Azikiwe Building,<br/>6th Floor, Azikiwe Street,<br/>Dar es Salaam, <br/>Tanzania.
            </li>
            <li>
              <span className="icon flaticon-phone-call"></span> <strong>Call us!</strong>022 211 6367
            </li>
            <li>
              <span className="icon flaticon-talk"></span><strong>Mail address</strong>info@lawassociates.co.tz
            </li>
          </ul>
          <div className="lower-box">
            {/* Social Icons */}
            <ul className="social-icons">
              <li className="facebook">
                <a href="https://www.linkedin.com/company/law-associate-advocates/">
                  <span className="fa fa-linkedin"></span>
                </a>
              </li>
              <li className="twitter">
                <a href="https://www.instagram.com/law_associates_advocates?igsh=ZzJ6cXEybmdqOTdx">
                  <span className="fa fa-instagram"></span>
                </a>
              </li>
              <li className="twitter">
                <a href="https://x.com/lawassociatestz?s=21">
                  <span className="fa fa-twitter"></span>
                </a>
              </li>
              {/* <li className="pinterest">
                <a href="#">
                  <span className="fa fa-pinterest-p"></span>
                </a>
              </li>
              <li className="vimeo">
                <a href="#">
                  <span className="fa fa-vimeo"></span>
                </a>
              </li> */}
            </ul>
            <a href="/contactus" className="theme-btn buy-btn">Contact Us Today</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HiddenBar;