import { Routes, Route } from "react-router";
import './App.css';
import Home from './pages/home/Home';
import ContactPage from './pages/contact';
import Services from './pages/services';
import Blog from './pages/blog';
import Management from './pages/management';
import TeamDetails from './pages/teamdetails';
import BlogSingle from './pages/blogdetails';

function App() {
  return (
    <Routes>
    <Route index element={<Home />} />
    <Route path="aboutus" element={<Management />} />
    <Route path="contactus" element={<ContactPage />} />
    <Route path="practiceareas" element={<Services />} />
    <Route path="publications" element={<Blog />} />
    <Route path="blog" element={<Blog />} />
    <Route path="/teamdetails/:id" element={<TeamDetails />} />
    <Route path="/teammember/:id" element={<TeamDetails />} />
    <Route path="/blogdetails/:id" element={<BlogSingle />} />
    {/* <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
    </Route>
  
    <Route path="concerts">
      <Route index element={<ConcertsHome />} />
      <Route path=":city" element={<City />} />
      <Route path="trending" element={<Trending />} />
    </Route> */}
  </Routes>
  );
}

export default App;
