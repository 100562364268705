import React, { useEffect } from 'react';

const BannerSection = () => {
  useEffect(() => {
    // Use window.$ to access the global jQuery object
    if (window.$ && window.$.fn.owlCarousel) {
      window.$('.main-slider-carousel').owlCarousel({
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
        autoHeight: true,
        autoplayHoverPause: true,
        smartSpeed: 500,
        autoplay: 6000,
        navText: [
          '<span class="fa fa-angle-left"></span>',
          '<span class="fa fa-angle-right"></span>',
        ],
        responsive: {
          0: { items: 1 },
          600: { items: 1 },
          800: { items: 1 },
          1024: { items: 1 },
          1200: { items: 1 },
        },
      });
    } else {
      console.error('jQuery or Owl Carousel is not loaded.');
    }
  }, []);

  return (
    <section className="banner-section">
      <div className="main-slider-carousel owl-carousel owl-theme">
        {/* Slide 1 */}
        <div
          className="slide"
          style={{
            backgroundImage: "url('https://res.cloudinary.com/dedfrilse/image/upload/v1737812665/Home-02_kz08ks.png')",
            position: 'relative',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to right, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0) 100%)',
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
            }}
            className="container"
          >
            <div className="content" style={{ color: 'white' }}>
              <div className="title">Welcome to Law Associates Advocates</div>
              <h1>
              With over 34 years of delivering reliable, impactful solutions.
              </h1>
              <a href="#" className="theme-btn btn-style-one">
                Contact us today
              </a>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        {/* <div
          className="slide"
          style={{
            backgroundImage: "url('https://res.cloudinary.com/dedfrilse/image/upload/v1737812670/Home-01_ruuacy.png')",
            position: 'relative',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to right, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0) 100%)',
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
            }}
            className="container"
          >
            <div className="content" style={{ color: 'white' }}>
              <div className="title">Introduce with LawSight</div>
              <h1>
                We are here to protect <br /> any kind of <span>Violance</span>
              </h1>
              <a href="#" className="theme-btn btn-style-one">
                Contact us today
              </a>
            </div>
          </div>
        </div> */}

        {/* Repeat other slides similarly */}
      </div>
    </section>
  );
};

export default BannerSection;