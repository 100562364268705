import React from 'react';
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import BannerSection from './bannersection';
import ServicesSection from './service';
import AboutSection from './about';
import ServicesSectionTwo from './servicetwo';
import CounterSection from './counter';
import TeamSection from './team';
import MainFooter from '../../components/homelayout/footer';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <div className="page-wrapper">
       <Helmet>
        <title>Law Associates Advocates | Over 3 Decades of Law Practice Globally</title>
      </Helmet>
      {/* Preloader */}
      {/* <div className="preloader"></div> */}

      {/* Main Header */}
      <Header />

      {/* Form Back Drop */}
      <div className="form-back-drop"></div>

      {/* Hidden Navigation Bar */}
      <HiddenBar />

      {/* Banner Section */}
      <BannerSection />

      {/* Services Section */}
      <ServicesSection/>

      {/* About Section */}
      <AboutSection />

      {/* Services Section Two */}
      <ServicesSectionTwo />

      {/* Counter Section */}
      <CounterSection />

      {/* Team Section */}
      <TeamSection />

      {/* Portfolio Section */}
      {/* <PortfolioSection /> */}

      {/* Form Section */}
      {/* <FormSection /> */}

      {/* News Section */}
      {/* <NewsSection /> */}

      {/* Subscribe Section */}
      {/* <SubscribeSection /> */}

      {/* Main Footer */}
      <MainFooter />
    </div>
  );
};

export default Home;