import React, { useEffect, useState} from 'react';
import Header from '../../components/homelayout/header';
import HiddenBar from '../../components/homelayout/hiddenbar';
import MainFooter from '../../components/homelayout/footer';
import { useParams } from "react-router";
import client from "../../configs/contentfulClient";
import { Helmet } from 'react-helmet-async';

const TeamDetails = () => {
    const { id } = useParams(); // Extract ID from URL
  const [teamMember, setTeamMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamMember = async () => {
      try {
        const response = await client.getEntry(id); // Fetch single entry
        if (!response) throw new Error("No data found");

        // Extract fields
        const { membername, education, description, linkedinLink, profilephoto } = response.fields;

        setTeamMember({
          name: membername,
          education,
          linkedin: linkedinLink,
          photo: profilephoto.fields.file.url,
          description
        });
      } catch (err) {
        setError("Failed to load team member.");
        console.error("Error fetching team member:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamMember();
  }, [id]);
  useEffect(() => {
    if (window.$) {
      // Add any jQuery-based initialization here if needed
    } else {
      console.error('jQuery is not loaded.');
    }
  }, []);


  return (
    <div className="page-wrapper">
       <Helmet>
                                      <title>Law Associates Advocates | Over 3 Decades of Law Practice Globally</title>
                                    </Helmet>
      {/* Preloader */}
      {/* <div className="preloader"></div> */}

      {/* Main Header */}
      <Header />

      {/* Form Back Drop */}
      <div className="form-back-drop"></div>

      {/* Hidden Bar */}
      <HiddenBar />

      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: "url('https://html.themexriver.com/lasight/images/background/4.jpg')" }}>
        <div className="container">
       
          <div className="content">
            <h1>Team Details</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Team Details</li>
            </ul>
          </div>
      
        </div>
      </section>

      {/* Our Team Title and Description */}
      <div style={{ marginTop: 100 }} className="section-title centered">
        <div className="titletwo">Team</div>
        <h3>
        Meet our trusted legal <br />{' '}
          <span className="spantwo">partner</span>
        </h3>
      </div>

      {/* Team Member Section with Photo and Content */}
      <div className="container">
      {loading ? (
          // **Loading Skeleton**
          <div className="loading-skeleton">
            <div className="skeleton-img"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-text"></div>
          </div>
        ) : error ? (
          // **Error Message**
          <p className="error-message">{error}</p>
        ) : (
        <div className="row">
          {/* Photo Card (col-2) */}
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="team-block">
              <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div className="image">
                  <a href="#">
                    <img
                      src={teamMember.photo}
                      alt={teamMember.name}
                    />
                  </a>
                </div>
                <div className="lower-content">
                  <h3>
                    <a href="#">{teamMember?.name}</a>
                  </h3>
                  <div className="designation">{teamMember?.education}</div>
                  <div className="overlay-box">
                    <div className="overlay-content">
                      <div className="title">Contact info</div>
                      <ul className="social-icons">
                        <li>
                          <a href={teamMember?.linkedin}>
                            <span className="fa fa-linkedin"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Content Section (col-10) */}
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="team-content">
              <p style={{ fontSize: 16 }}>
                {teamMember?.description}
              </p>
              {/* <p style={{ fontSize: 16 }}>
                With over 20 years of experience, Dr. Tenga has represented clients in high-stakes litigation and
                arbitration cases across multiple jurisdictions. His expertise spans commercial law, intellectual
                property, and international trade.
              </p>
              <p style={{ fontSize: 16 }}>
                Dr. Tenga is also a published author and a frequent speaker at international legal conferences. His
                work has been instrumental in shaping legal frameworks in emerging markets.
              </p>
              <p style={{ fontSize: 16 }}>
                In addition to his legal practice, Dr. Tenga is actively involved in mentoring young lawyers and
                contributing to legal education initiatives. He is a strong advocate for diversity and inclusion in
                the legal profession.
              </p> */}
            </div>
          </div>
        </div>
        )}
      </div>

      {/* Footer */}
      <MainFooter />
    </div>
  );
};

export default TeamDetails;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router";
// import Header from "../../components/homelayout/header";
// import HiddenBar from "../../components/homelayout/hiddenbar";
// import MainFooter from "../../components/homelayout/footer";
// import client from "../../configs/contentfulClient";

// const TeamDetails = () => {
//   const { id } = useParams(); // Extract ID from URL
//   const [teamMember, setTeamMember] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchTeamMember = async () => {
//       try {
//         const response = await client.getEntry(id); // Fetch single entry
//         if (!response) throw new Error("No data found");

//         // Extract fields
//         const { membername, education, linkedinLink, profilephoto } = response.fields;


//         setTeamMember({
//           name: membername,
//           education,
//           linkedin: linkedinLink,
//           photo: profilephoto.fields.file.url,
//         });
//       } catch (err) {
//         setError("Failed to load team member.");
//         console.error("Error fetching team member:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTeamMember();
//   }, [id]);

//   return (
//     <div className="page-wrapper">
//       <Header />
//       <HiddenBar />

//       <section
//         className="page-title"
//         style={{
//           backgroundImage:
//             "url('https://html.themexriver.com/lasight/images/background/4.jpg')",
//         }}
//       >
//         <div className="container">
//           <div className="content">
//             <h1>Team Details</h1>
//             <ul className="page-breadcrumb">
//               <li>
//                 <a href="/">Home</a>
//               </li>
//               <li>Team Details</li>
//             </ul>
//           </div>
//         </div>
//       </section>

//       <div className="container">
//         {loading ? (
//           // **Loading Skeleton**
//           <div className="loading-skeleton">
//             <div className="skeleton-img"></div>
//             <div className="skeleton-text"></div>
//             <div className="skeleton-text"></div>
//           </div>
//         ) : error ? (
//           // **Error Message**
//           <p className="error-message">{error}</p>
//         ) : (
//           // **Team Member Content**
//           <div className="row">
//             {/* Photo Section */}
//             <div className="col-lg-3 col-md-4 col-sm-12">
//               <div className="team-block">
//                 <div className="inner-box">
//                   <div className="image">
//                     <img
//                       src={teamMember.photo}
//                       alt={teamMember.name}
//                     />
//                   </div>
//                   <div className="lower-content">
//                     <h3>{teamMember.name}</h3>
//                     <div className="designation">{teamMember.education}</div>
//                     <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer">
//                       <span className="fa fa-linkedin"></span> LinkedIn
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Description Section */}
//             <div className="col-lg-9 col-md-8 col-sm-12">
//               <div className="team-content">
//                 <p>{teamMember.name} is an expert in corporate law and dispute resolution, having graduated from {teamMember.education}. He has extensive experience in legal consultancy and advocacy.</p>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       <MainFooter />
//     </div>
//   );
// };

// export default TeamDetails;
