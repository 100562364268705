import React from 'react';

const ServicesSection = () => {
  return (
    <section className="services-section">
      <div className="icon-one wow zoomIn" data-wow-delay="250ms" data-wow-duration="1500ms" style={{ backgroundImage: "url(images/icons/icon-1.png)" }}></div>
      <div className="icon-two wow fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms" style={{ backgroundImage: "url(images/icons/icon-2.png)" }}></div>
      <div className="container">
        <div className="row clearfix">
          {/* Services Block */}
          <div className="services-block col-lg-4 col-md-6 col-sm-12">
            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div className="border-one"></div>
              <div className="border-two"></div>
              <div className="content">
                <div className="icon-box">
                  <span className="icon flaticon-family"></span>
                </div>
                <h6><a href="services-detail.html">Proven Legal Excellence</a></h6>
                <div className="text">Delivering strategic legal solutions with a strong commitment to justice.</div>
              </div>
            </div>
          </div>
           {/* Services Block */}
           <div className="services-block col-lg-4 col-md-6 col-sm-12">
            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div className="border-one"></div>
              <div className="border-two"></div>
              <div className="content">
                <div className="icon-box">
                  <span className="icon flaticon-fingerprint-1"></span>
                </div>
                <h6><a href="services-detail.html">Comprehensive Legal Advisory
                </a></h6>
                <div className="text">Tailored legal guidance to protect your rights and interests.
                </div>
              </div>
            </div>
          </div>
           {/* Services Block */}
           <div className="services-block col-lg-4 col-md-6 col-sm-12">
            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div className="border-one"></div>
              <div className="border-two"></div>
              <div className="content">
                <div className="icon-box">
                  <span className="icon flaticon-non-stop-call"></span>
                </div>
                <h6><a href="services-detail.html">Strategic Legal Solutions</a></h6>
                <div className="text">Ensuring legal compliance and safeguarding business operations.</div>
              </div>
            </div>
          </div>
          {/* Repeat other services blocks similarly */}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;