import React, { useEffect, useState } from 'react';
import client from '../../configs/contentfulClient';

const MainFooter = () => {
  const [teamMembers, setTeamMembers] = useState([]);


  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await client.getEntries({ content_type: 'gallery' });

        // Reverse the order of items
        const sortedMembers = response.items.sort((a, b) => 
          new Date(a.sys.createdAt) - new Date(b.sys.createdAt)
        );

        setTeamMembers(sortedMembers);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);
  
  return (
    <footer className="main-footer">
      <div className="container">
        {/*Widgets Section*/}
        <div className="widgets-section">
          <div className="row clearfix">
            {/*Column*/}
            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/*Footer Column*/}
                <div className="footer-column col-lg-7 col-md-6 col-sm-12">
                  <div className="footer-widget logo-widget">
                    <div className="logo">
                      <a href="index.html">
                        <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737803806/LAA_Logo-03_dwtnrf.png" alt="" />
                      </a>
                    </div>
                    <div className="text">We are a Well Established and Experienced Law Firm, Offering a Full Range of Legal Services with a High Level of Professionalism since 1991.</div>
                    <ul className="list-style-three">
                      <li><span className="icon fa fa-phone"></span> 022 211 6367 </li>
                      <li><span className="icon fa fa-envelope"></span>info@lawassociates.co.tz</li>
                      <li><span className="icon fa fa-home"></span>CRDB Azikiwe Building, 6th floor, <br />Azikiwe street, Dar es salaam,<br/> Tanzania.</li>
                    </ul>
                  </div>
                </div>
                {/*Footer Column*/}
                <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4>Links</h4>
                    <ul className="list-link">
                      <li><a href="/">Home</a></li>
                      <li><a href="/practiceareas">Services</a></li>
                      <li><a href="/aboutus">About us</a></li>
                      <li><a href="/contactus">Contact</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*Column*/}
            <div className="big-column col-lg-6 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/*Footer Column*/}
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h4>Support</h4>
                    <ul className="list-link">
                      <li><a href="/contactus">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
                {/*Footer Column*/}
                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-widget gallery-widget">
                    <h4>Gallery</h4>
                    <div className="widget-content">
                      <div className="images-outer clearfix">
                        {/*Image Box*/}
                        {teamMembers.length > 0 ? (
                      teamMembers.map((member) => {
                        const { id } = member.sys;
                        const { thumbanail, photo } = member.fields;
          
                        return (
                        <figure className="image-box">
                          <a href={thumbanail.fields.file.url} className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src={photo.fields.file.url} alt="" />
                          </a>
                        </figure>
                        )})) : null}
                        {/* <figure className="image-box">
                          <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1737813503/Gallery-02_uipzc8.png" className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737813503/Gallery-02_uipzc8.png" alt="" />
                          </a>
                        </figure>
                        <figure className="image-box">
                          <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1737813499/Gallery-03_oudiyd.png" className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737813499/Gallery-03_oudiyd.png" alt="" />
                          </a>
                        </figure>
                        <figure className="image-box">
                          <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1737813509/Gallery-04_hu5sgr.png" className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737813509/Gallery-04_hu5sgr.png" alt="" />
                          </a>
                        </figure>
                        <figure className="image-box">
                          <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1737813512/Gallery-05_zpvh3v.png" className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737813512/Gallery-05_zpvh3v.png" alt="" />
                          </a>
                        </figure>
                        <figure className="image-box">
                          <a href="https://res.cloudinary.com/dedfrilse/image/upload/v1737813506/Gallery-06_zqo1ot.png" className="lightbox-image" data-fancybox="footer-gallery" title="Image Title Here" data-fancybox-group="footer-gallery">
                            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737813506/Gallery-06_zqo1ot.png" alt="" />
                          </a>
                        </figure> */}
                        {/* Repeat other image boxes similarly */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row clearfix">
              {/* Copyright Column */}
              <div className="copyright-column col-lg-6 col-md-6 col-sm-12">
                <div className="copyright">2025 &copy; All rights Reserved. Designed by: <a href="https://essence.co.tz">Essence Creative</a></div>
              </div>
              {/* Social Column */}
              <div className="social-column col-lg-6 col-md-6 col-sm-12">
                <ul>
                  <li className="follow">Follow us: </li>
                  <li><a href="https://www.instagram.com/law_associates_advocates?igsh=ZzJ6cXEybmdqOTdx"><span className="fa fa-instagram"></span></a></li>
                  <li><a href="https://www.linkedin.com/company/law-associate-advocates/"><span className="fa fa-linkedin-square"></span></a></li>
                  <li><a href="https://x.com/lawassociatestz?s=21"><span className="fa fa-twitter-square"></span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;