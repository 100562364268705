import React, { useEffect, useState } from 'react';
import { Link } from 'react-router'; // Fixed incorrect import
import client from '../../configs/contentfulClient';

const TeamSection = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await client.getEntries({ content_type: 'partners' });
        console.log(response);
  
        // Sort the items based on createdAt in ascending order (oldest first)
        const sortedMembers = response.items.sort((a, b) => 
          new Date(a.sys.createdAt) - new Date(b.sys.createdAt)
        );
  
        setTeamMembers(sortedMembers);
      } catch (error) {
        console.error("Error fetching team members:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTeamMembers();
  }, []);
  

  return (
    <section className="team-section">
      <div className="container">
        {/* Section Title */}
        <div className="section-title light">
          <div className="clearfix">
            <div className="pull-left">
              <div className="title">Our Team</div>
              <h3>Meet Our Esteemed Partners</h3>
            </div>
            <div className="pull-right">
              <div className="text">
                Our team of dedicated legal professionals brings years of expertise and a strong commitment to excellence. Each member is driven by a passion for justice and client success, ensuring top-tier legal solutions tailored to your needs.
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix">
          {loading
            ? [...Array(4)].map((_, index) => (
                <div key={index} className="team-block col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box skeleton">
                    <div className="image skeleton-box"></div>
                    <div className="lower-content">
                      <h3 className="skeleton-box" style={{ width: '80%' }}></h3>
                      <div className="designation skeleton-box" style={{ width: '60%' }}></div>
                    </div>
                  </div>
                </div>
              ))
            : teamMembers.map((member) => {
                const { id } = member.sys;
                const { membername, education, profilephoto, linkedinlink } = member.fields;

                return (
                  <div key={id} className="team-block col-lg-3 col-md-6 col-sm-12">
                    <Link to={`/teamdetails/${id}`}>
                      <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="image">
                          <img src={profilephoto.fields.file.url} alt={membername} />
                        </div>
                        <div className="lower-content">
                          <h3>{membername}</h3>
                          <div className="designation">{education}</div>
                          <div className="overlay-box">
                            <div className="overlay-content">
                              <div className="title">Contact info</div>
                              <ul className="social-icons">
                                <li>
                                  <a href={linkedinlink} target="_blank" rel="noopener noreferrer">
                                    <span className="fa fa-linkedin"></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;

