import React from 'react';

const CounterSection = () => {
  return (
    <section className="counter-section">
      <div className="icon-one wow zoomIn" data-wow-delay="250ms" data-wow-duration="1500ms" style={{ backgroundImage: "url(images/icons/icon-3.png)" }}></div>
      <div className="icon-two wow fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms" style={{ backgroundImage: "url(images/icons/icon-4.png)" }}></div>
      <div className="container">
        {/* Title Box */}
        <div className="title-box">
          <div className="section-title">
            <div className="row clearfix">
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <div className="title">Fun fact</div>
                <h3>We are very  proud of our <br /> legal <span>accomplishments</span></h3>
              </div>
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p>Our achievements reflect our dedication to justice, professionalism and client success.</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <p>We have successfully represented clients in high profile cases with effective legal solutions.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fact Counter */}
        <div className="fact-counter">
          <div className="row clearfix">
            {/*Column*/}
            <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div className="count-outer count-box">
                  <span className="count-text" data-speed="3000ms" data-stop="1825">34</span>+
                  <div className="counter-title">Years of Experience</div>
                </div>
              </div>
            </div>
            <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
						<div class="inner wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{visibility: "visible", animationDuration: "1500ms", animationDelay: "0ms", animationName: "fadeInLeft"}}>
							<div class="count-outer count-box counted">
								<span class="count-text" data-speed="2000" data-stop="532">28</span>+
								<div class="counter-title">Experts</div>
							</div>
						</div>
					</div>
          <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
              <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div className="count-outer count-box">
                  <span className="count-text" data-speed="3000ms" data-stop="1825">800</span>+
                  <div className="counter-title">Satisfied Clients</div>
                </div>
              </div>
            </div>
            <div class="column counter-column col-lg-3 col-md-6 col-sm-12">
						<div class="inner wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{visibility: "visible", animationDuration: "1500ms", animationDelay: "0ms", animationName: "fadeInLeft"}}>
							<div class="count-outer count-box counted">
								<span class="count-text" data-speed="2000" data-stop="532">⁠14</span>+
								<div class="counter-title">Practice Areas</div>
							</div>
						</div>
					</div>
            {/* Repeat other counter columns similarly */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;