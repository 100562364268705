import React from 'react';

const AboutSection = () => {
  return (
    <section className="about-section">
      {/* Image Layer */}
    
        <div className="row clearfix">
          {/* Content Column */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12">
          <div className="container">
            <div style={{paddingLeft: 40, paddingRight: 40, paddingTop: 40}} className="inner-column">
              {/* Sec Title */}
              <div className="section-title">
                <div className="title">about us</div>
                <h3>Dedicated to upholding justice with expertise and <span>experience</span></h3>
              </div>
              <div className="text">
                <p>At Law Associates & Advocates, we bring over 34 years of experience in protecting your rights and providing strategic legal solutions for every challenge.With more than three decades of experience, we combine deep legal knowledge with a proven track record of success to ensure your case is handled with the utmost professionalism and expertise. Trust in our legacy of delivering results.
Our Focus:
</p>
               
              </div>
              <div className="row clearfix">
                <div className="column col-lg-12 col-md-6 col-sm-12">
                  <ul className="list-style-one">
                    <li>Legal advocacy with integrity and precision</li>
                    <li>Comprehensive legal consultation tailored to your needs</li>
                    <li>Strong representation in disputes and legal matters</li>
                  </ul>
                </div>
                {/* Repeat other list items similarly */}
              </div>
              {/* <div className="question">Call to ask <a href="#">any question</a> <strong>540-325-1523</strong> <span className="or">or</span> <strong>540-328-1265</strong></div>
              <div className="signature">
                <div className="signature-img">
                  <img src="images/icons/signature.png" alt="" />
                </div>
                <h5>Natalia Duke</h5>
                <div className="designation">(Chairman and founder)</div>
              </div> */}
            </div>
            </div>
          </div>
          {/* Image Column */}
        
          <div className="col-lg-6 col-md-12 col-sm-12" 
          style={{ backgroundImage: "url('https://res.cloudinary.com/dedfrilse/image/upload/v1739874304/uvudjnzu2eqil8n1xuh4.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', height: '600px' }}>
        </div>
       
  
      </div>
    </section>
  );
};

export default AboutSection;