import React, { useEffect } from 'react';

const ServicesSectionTwo = () => {
  useEffect(() => {
    // Ensure jQuery is available globally
    if (window.$ && window.$.fn.owlCarousel) {
      // Initialize Services Carousel (3 items per scroll)
      if (window.$('.services-carousel').length) {
        window.$('.services-carousel').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          dots: false, // Disable dots (optional, since we're enabling them below)
          autoplay: true,
          autoplayTimeout: 6000,
          smartSpeed: 500,
          navText: [
            '<span class="fa fa-angle-left"></span>',
            '<span class="fa fa-angle-right"></span>',
          ],
          responsive: {
            0: { items: 1 },
            600: { items: 2 },
            800: { items: 2 },
            1024: { items: 3 },
            1200: { items: 3 },
          },
        });
  
        // Remove the "disabled" class from .owl-nav
      window.$('.services-section-two .owl-carousel .owl-nav').removeClass('disabled');
      }
    } else {
      console.error('jQuery or Owl Carousel is not loaded.');
    }
  }, []);

  return (
    <section className="services-section-two" style={{ backgroundImage: "url(https://res.cloudinary.com/dedfrilse/image/upload/v1737814577/BC_zozeyu.png)" }}>
      <div className="container">
        {/* Sec Title */}
        <div className="section-title light centered">
          <div className="title">Practice Areas</div>
          <h3>We are committed to protecting your <br /> rights with  <span>expertise of our experienced professionals</span></h3>
        </div>

        {/* Services Carousel */}
        <div className="services-carousel owl-carousel owl-theme">
          {/* Service Block 1 */}
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>LAND LAW AND PROPERTY CONVEYANCING</h3>
              <div className="text">The Firm specializes in property law, offering acquisition, development, documentation, registration, real estate advice, and litigation services.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-1.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">LAND LAW AND PROPERTY CONVEYANCING</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block 2 */}
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>MINING, ENERGY, OIL AND GAS</h3>
              <div className="text">Tanzania’s rich minerals and energy resources attract investors. The Firm provides expert legal services to support investment and compliance.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-2.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">MINING, ENERGY, OIL AND GAS</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Service Block 3 */}
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>CORPORATE LAW</h3>
              <div className="text">The Firm handles corporate legal needs, including company formation, transactions, contracts, due diligence, tax, and stock exchange listings.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">CORPORATE LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Repeat other service blocks similarly */}
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>SECRETARIAL SERVICES SUPPORT</h3>
              <div className="text">The Firm's Company Secretarial Department ensures corporate compliance, statutory record-keeping, governance, and advisory services for local and multinational companies.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">SECRETARIAL SERVICES SUPPORT</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>INTELLECTUAL PROPERTY LAW</h3>
              <div className="text">The Firm provides comprehensive Intellectual Property services, including registration, protection, litigation, and advisory on trademarks, patents, copyrights, and designs.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">INTELLECTUAL PROPERTY LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>BANKING AND FINANCIAL SERVICES</h3>
              <div className="text">The Firm provides expert legal services in banking and finance, including regulatory compliance, documentation, securities registration, restructuring, and litigation.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">BANKING AND FINANCIAL SERVICES</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>LABOUR AND EMPLOYMENT LAW</h3>
              <div className="text">The Firm provides comprehensive employment and labour law services, including contracts, dispute resolution, compliance, litigation, and work permit processing.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">LABOUR AND EMPLOYMENT LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>REGULATORY LAW</h3>
              <div className="text">The Firm specializes in regulatory compliance, competition law, mergers, and litigation, offering expert guidance across various industries in Tanzania.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">REGULATORY LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>AVIATION, CARRIAGE BY SEA AND MARITIME LAW</h3>
              <div className="text">The Firm specializes in aviation, shipping, and maritime law, offering expertise in insurance, claims, licensing, accidents, and international conventions.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">AVIATION, CARRIAGE BY SEA AND MARITIME LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>COMPETITION, MERGERS & ACQUISITION</h3>
              <div className="text">The Firm has extensive experience advising and representing clients on Competition Law and Mergers & Acquisitions, both locally and internationally.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">COMPETITION, MERGERS & ACQUISITION</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>TAX LAW</h3>
              <div className="text">The Firm offers expert tax services, including advice on structuring, transfer pricing, tax disputes, negotiations with authorities, and litigation.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">TAX LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>INSURANCE LAW</h3>
              <div className="text">The Firm provides legal services in insurance, advising on policy liability, regulatory compliance, claims resolution, and litigation across various insurance sectors.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">INSURANCE LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>ENVIRONMENTAL LAW</h3>
              <div className="text">The Firm provides environmental law services, including advice on legislation, risk assessments, impact studies, litigation, dispute resolution, and compliance.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">ENVIRONMENTAL LAW</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-block-two">
            <div className="inner-box">
              <div className="icon-box" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
              </div>
              <h3>GENERAL LITIGATION AND ALTERNATIVE DISPUTE RESOLUTION</h3>
              <div className="text">The Firm provides expert litigation services in various fields, including banking, telecommunications, construction, mining, fraud, personal injury, land, and criminal law.</div>
              <div className="overlay-box" style={{ backgroundImage: "url(images/resource/service-3.jpg)" }}>
                <div className="overlay-inner">
                  <div className="content">
                    <img style={{width: 40, alignSelf: 'center'}} src='https://res.cloudinary.com/dedfrilse/image/upload/v1739536097/qbsgqkhtxioojcocbvxo.png' />
                    <h4><a href="/contactus">GENERAL LITIGATION AND ALTERNATIVE DISPUTE RESOLUTION</a></h4>
                    <a href="/contactus" className="theme-btn btn-style-one">consult now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSectionTwo;