import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const Header = () => {
    useEffect(() => {
        // Ensure jQuery is available globally
        if (window.$) {
          // Header Style & Scroll to Top
          function headerStyle() {
            if (window.$('.main-header').length) {
              const windowpos = window.$(window).scrollTop();
              const siteHeader = window.$('.main-header');
              const scrollLink = window.$('.scroll-to-top');
    
              if (windowpos >= 1) {
                siteHeader.addClass('fixed-header');
                scrollLink.fadeIn(300);
              } else {
                siteHeader.removeClass('fixed-header');
                scrollLink.fadeOut(300);
              }
            }
          }
    
          // Run headerStyle on scroll
          window.$(window).on('scroll', headerStyle);
          headerStyle(); // Initial call
    
          // Dropdown Toggle
          if (window.$('.main-header li.dropdown ul').length) {
            window.$('.main-header li.dropdown').append(
              '<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>'
            );
    
            // Dropdown Button Click
            window.$('.main-header li.dropdown .dropdown-btn').on('click', function () {
              window.$(this).prev('ul').slideToggle(500);
            });
    
            // Disable dropdown parent link
            window.$('.main-header .navigation li.dropdown > a, .hidden-bar .side-menu li.dropdown > a').on(
              'click',
              function (e) {
                e.preventDefault();
              }
            );
          }
    
          // Hidden Sidebar
          if (window.$('.hidden-bar').length) {
            const hiddenBar = window.$('.hidden-bar');
            const hiddenBarOpener = window.$('.nav-btn');
            const hiddenBarCloser = window.$('.hidden-bar-closer');
    
            // Initialize custom scrollbar
            window.$('.hidden-bar-wrapper').mCustomScrollbar();
    
            // Show Sidebar
            hiddenBarOpener.on('click', function () {
              hiddenBar.addClass('visible-sidebar');
            });
    
            // Hide Sidebar
            hiddenBarCloser.on('click', function () {
              hiddenBar.removeClass('visible-sidebar');
            });
          }
    
          // Fixed Right Top Consultation Form Toggle
          if (window.$('.main-header .header-upper .outer-box .nav-toggler').length) {
            // Show Form
            window.$('.main-header .header-upper .outer-box .nav-toggler').on('click', function (e) {
              e.preventDefault();
              window.$('body').addClass('background-visible');
            });
    
            // Hide Form
            window.$('.form-back-drop').on('click', function (e) {
              e.preventDefault();
              window.$('body').removeClass('background-visible');
            });
    
            window.$('.form-back-drop').on('click', function (e) {
              window.$('.hidden-bar').removeClass('visible-sidebar');
            });
    
            window.$('.hidden-bar .hidden-bar-closer').on('click', function (e) {
              window.$('body').removeClass('background-visible');
            });
          }
        } else {
          console.error('jQuery is not loaded.');
        }
      }, []); // Empty dependency array ensures this runs only once after the component mounts
  return (
    <header className="main-header">
      {/* Header Upper */}
      <div className="header-upper">
        <div className="container">
          <div className="clearfix">
            {/* Logo Box */}
            <div className="pull-left logo-box">
              <div className="logo">
                <a href="/">
                  <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737803805/LAA_Logo-01_1_wwkwi1.png" alt="" title="" />
                </a>
              </div>
            </div>
            {/* Navigation and Search Box */}
            <div className="nav-outer clearfix">
              <MainMenu />
              {/* <SearchBox /> */}
              <NavToggler />
            </div>
          </div>
        </div>
      </div>
      {/* Sticky Header */}
      <StickyHeader />
    </header>
  );
};

const MainMenu = () => {
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/aboutus', label: 'About Us' },
    { path: '/practiceareas', label: 'Practice Areas' },
    { path: '/publications', label: 'Publications' },
    { path: '/contactus', label: 'Contact Us' },
  ];

  return (
    <nav className="main-menu navbar-expand-md">
      <div className="navbar-header">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
        <ul className="navigation clearfix">
          {navItems.map((item) => (
            <li key={item.path} className={location.pathname === item.path ? 'current' : ''}>
              <a href={item.path}>{item.label}</a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};


const SearchBox = () => {
  return (
    <div className="search-box-outer">
      <div className="dropdown">
        <button className="search-box-btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="fa fa-search"></span>
        </button>
        <ul className="dropdown-menu pull-right search-panel" aria-labelledby="dropdownMenu1">
          <li className="panel-outer">
            <div className="form-container">
              <form method="post" action="blog.html">
                <div className="form-group">
                  <input type="search" name="field-name" value="" placeholder="Search Here" required />
                  <button type="submit" className="search-btn">
                    <span className="fa fa-search"></span>
                  </button>
                </div>
              </form>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const NavToggler = () => {
  return (
    <div className="outer-box">
 <div className="nav-toggler">
      <div className="nav-btn hidden-bar-opener">
        <span style={{color: '#711039'}} className="flaticon-menu"></span>
      </div>
    </div>
    </div>
   
  );
};

const StickyHeader = () => {
  return (
    <div className="sticky-header">
      <div className="container clearfix">
        {/* Logo */}
        <div className="logo pull-left">
          <a href="index.html" className="img-responsive">
            <img src="https://res.cloudinary.com/dedfrilse/image/upload/v1737802749/LAA_Logo-02_r2bl7w.png" alt="" title="" />
          </a>
        </div>
        {/* Right Col */}
        <div className="right-col pull-right">
          <MainMenu />
        </div>
      </div>
    </div>
  );
};

export default Header;