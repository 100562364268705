import React, { useEffect } from 'react';
import MainFooter from '../../components/homelayout/footer';
import HiddenBar from '../../components/homelayout/hiddenbar';
import Header from '../../components/homelayout/header';
import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
  // Use useEffect to handle jQuery-based functionality
  useEffect(() => {
    if (window.$) {
      // Add any jQuery-based initialization here if needed
    } else {
      console.error('jQuery is not loaded.');
    }
  }, []);

  return (
    <div className="page-wrapper">
       <Helmet>
                    <title>Contact Us | Law Associates Advocates</title>
                  </Helmet>
      {/* Preloader */}
      {/* <div className="preloader"></div> */}

      {/* Main Header */}
    <Header/>
      {/* Form Back Drop */}
      <div className="form-back-drop"></div>

      {/* Hidden Navigation Bar */}
      <HiddenBar/>

      {/* Page Title */}
      <section className="page-title" style={{ backgroundImage: "url('https://html.themexriver.com/lasight/images/background/4.jpg')" }}>
        <div className="container">
          <div className="content">
            <h1>Contact</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Contact Page Section */}
      <section className="contact-page-section">
        <div className="map-section">
          {/* Map Outer */}
          <div className="map-outer">
            {/* Map Canvas */}
            <div
              className="map-canvas"
              data-zoom="12"
              data-lat="-37.817085"
              data-lng="144.955631"
              data-type="roadmap"
              data-hue="#ffc400"
              data-title="Envato"
              data-icon-path="images/icons/map-marker.png"
              data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>"
            ></div>
          </div>
        </div>
        <div className="container">
          <div className="inner-container">
            <h2>
            Contact us for support or to schedule a <span>consultation.</span>
            </h2>
            <div className="row clearfix">
              {/* Info Column */}
              <div className="info-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="text">
                  Visit our website for more on our management consulting services
                  </div>
                  <ul className="list-style-six">
                    <li>
                      <span className="icon fa fa-building"></span> CRDB Azikiwe Building, <br /> 6th Floor, Azikiwe Street, Dar es Salaam, <br /> Tanzania.
                    </li>
                    <li>
                      <span className="icon fa fa-fax"></span> 022 211 6367
                    </li>
                    <li>
                      <span className="icon fa fa-envelope-o"></span>info@lawassociates.co.tz
                    </li>
                  </ul>
                </div>
              </div>

              {/* Form Column */}
              <div className="form-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  {/* Contact Form */}
                  <div className="contact-form">
                    <form method="post" action="sendemail.php" id="contact-form">
                      <div className="form-group">
                        <input type="text" name="firstname" value="" placeholder="Full name" required />
                      </div>
                      <div className="form-group">
                        <input type="text" name="email" value="" placeholder="Email" required />
                      </div>
                      <div className="form-group">
                      <select className="custom-select-box">
  <option>Select topic</option>
  <option>LAND LAW AND PROPERTY CONVEYANCING</option>
  <option>MINING, ENERGY, OIL AND GAS</option>
  <option>CORPORATE LAW</option>
  <option>SECRETARIAL SERVICES SUPPORT</option>
  <option>INTELLECTUAL PROPERTY LAW</option>
  <option>BANKING AND FINANCIAL SERVICES</option>
  <option>LABOUR AND EMPLOYMENT LAW</option>
  <option>REGULATORY LAW</option>
  <option>AVIATION, CARRIAGE BY SEA AND MARITIME LAW</option>
  <option>COMPETITION, MERGERS & ACQUISITION</option>
  <option>TAX LAW</option>
  <option>INSURANCE LAW</option>
  <option>ENVIRONMENTAL LAW</option>
  <option>GENERAL LITIGATION AND ALTERNATIVE DISPUTE RESOLUTION</option>
</select>

                      </div>
                      <div className="form-group">
                        <textarea name="message" placeholder="write.."></textarea>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="theme-btn btn-style-one">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Info Section */}
      <section className="contact-info-section" >
        <div className="container">
          <div className="row clearfix">
            <div className="column col-lg-4 col-md-6 col-sm-12">
              <h4>United Kingdom</h4>
              <ul className="list-style-seven">
                <li>
                  <span className="icon flaticon-map-1"></span> 49488 Avenida Obregon, <br /> La Quinta, CA 92253
                </li>
                <li>
                  <span className="icon flaticon-call-answer"></span> +1-(281)-813 926 <br /> +1-(281)-813 612
                </li>
                <li>
                  <span className="icon fa fa-envelope-o"></span>support@lawsight.com.uk
                </li>
              </ul>
            </div>
            <div className="column col-lg-4 col-md-6 col-sm-12">
              <h4>Australia</h4>
              <ul className="list-style-seven">
                <li>
                  <span className="icon flaticon-map-1"></span> 13/1 Dixon Street, Sydney <br /> NSW 2000
                </li>
                <li>
                  <span className="icon flaticon-call-answer"></span> +1-(281)-813 926 <br /> +1-(281)-813 612
                </li>
                <li>
                  <span className="icon fa fa-envelope-o"></span>support@lawsight.com.uk
                </li>
              </ul>
            </div>
            <div className="column col-lg-4 col-md-6 col-sm-12">
              <h4>Netherlands</h4>
              <ul className="list-style-seven">
                <li>
                  <span className="icon flaticon-map-1"></span> Nieuwe Leliestraat 27-HS <br /> 101J Amsterdam
                </li>
                <li>
                  <span className="icon flaticon-call-answer"></span> +1-(281)-813 926 <br /> +1-(281)-813 612
                </li>
                <li>
                  <span className="icon fa fa-envelope-o"></span>support@lawsight.com.uk
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Main Footer */}
     <MainFooter/>
    </div>
  );
};

export default ContactPage;